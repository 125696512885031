<template>
<nav>
    <ul>
        <li><router-link to="/Skanerrak">Skanerrak</router-link></li>
        <li><router-link to="/Skanerrak/History">History</router-link></li>
        <li><router-link to="/Skanerrak/Geography">Geography</router-link></li>
        <li><router-link to="/Skanerrak/Terminology">Terminology</router-link></li>       
        <li><router-link to="/Skanerrak/Characters">Characters</router-link></li>
        <li><a>Stories</a></li> |
        <li><router-link to="/">Home</router-link></li>
    </ul>
  </nav>

  <div class="top"></div>


    <div class="blurb">
        <h2>Will, Light in the Darkness</h2>

<p class="text-center">


Cry a requiem for sunlight<br>
Hide away now from the dark<br>
To me the angered deep is calling<br>
Tearing me apart<br>
Somehow the whispers do command me<br>
When the abyss has taken hold<br>
Now carry on in glory<br>
When I grow cold
<br><b>Song of the Abyss, Aviators</b></p>

   </div>

    <div class="fluid-boxes">
      <div class="box">
        <p><b>Likes: </b>Being in good company<br><b>Dislikes: </b>People fuzzing over him<br><b>Strength: </b>Unwavering fortitude<br><b>Weakness: </b>Lack of self preservation<br><b>Alignment:</b> Neutral<br><b>Symbolic elements: </b> light mixed with darkness
        </p>
      </div>



      <div class="box">
        <p><b>Physical Strength: </b>4/10<br><b>Psionic Strength: </b>N/A<br><b>Intelligence: </b>8/10<br><b>Constitution: </b>10/10 <br><b>Charisma: </b>5/10<br><b>Perception: </b>6/10<br><b>Courage: </b>8/10 <br><b>Impulsiveness: </b>9/10 <br><b>Seriousness: </b>9/10 <br><b>Compassion: </b>8/10</p>
      </div>
    </div>

    <div class="blurb">
        <h2>Random Fact</h2>
<p>
Will and Dela are meant to be identical twins, despite them unambiguously presenting as male and female respectively. Maybe it was part of the Time anomaly? I guess we will never know. 0:-)
</p>

<hr><h2>Relations</h2>
<ul class="list">
<li><h2>Dela</h2></li>
<li><h2>Annie</h2></li>
<li><h2>Myra</h2></li>
<li><h2>Riley</h2></li>
<li><h2>Stiaerna</h2></li>
<li><h2>Steffen</h2></li>
<li><h2>Ander</h2></li>
<li><h2>Kira</h2></li>



</ul><hr><h2>Appearance</h2>
<div class="ref">
<h2><span>Human Form</span></h2>
<img src="../../../assets/portraits/Will.png">
<p>
<b>Build: </b>Relatively tall. Pale skin that glows slightly. Faint webs of darker tendrils can be seen occasionally, usually around his torso.<br><b>Hairstyle: </b>Medium length unkempt hair<br><b>Hair color: </b>Black<br><b>Eye color:</b> Bright blue, slightly glowing<br><b>Clothing:</b> loose and oversized <br><b>Accessories: </b>Enchanted, glowing, floating shoulder guard over his left shoulder<br class="clr"></p></div>



<div class="ref">
<h2><span>Spirit Form</span></h2>
<p>Early form is rarely depicted outside of select moments. A dark colored fox-like canine with glowing yellow-white eyes and a faintly glowing purple streak across the torso. Has wings with lighted up tips, but usually either not seen or folded to the side with the lighted tips mostly obscured. </p>
<p>Late/free spirit form is basically glowing blobs or orbs with a darker colored core.</p>

<br class="clr"></div>


<div class="ref">
<h2><span>Neopets Form</span></h2>
<img src="http://i.imgur.com/iHplfmg.png"><p>Can be depicted as a dark blue Shoyru with membranous wings that gradients from dark blue to glowing, semi-transparent yellow. No hands/feet. Can be used to represent either the human form (relatively larger) or the spirit orbs (smol). </p>
<br class="clr"></div>



<hr><h2>Character Inspiration</h2>
<p>
Will's character (or more accurately, his existence) was inspired by a real life person, mostly his (somewhat uncommon) name, when I was about 10. He became my "pet character" for a very very long time after that, only briefly being pushed to the sidelines as I got obsessed with first XCOM2 writing and then the Skanerrak storylines. He and his story was originally a melting pot of pretty much every fictional or real character that I liked that I've ever come across, the most notable inspiration being the Ultraman Tiga TV series that I was forced to watch with my cousin.
</p>
<p>
Over the years I have made multiple attempts to write an "ending" to his storyline, but I could never give him up entirely, and he always made a return, sometimes with a little rework or retconning. As a result of this and the melting pot nature of the process, his storyline ended up being extremely disjointed and full of snippets, rather than a single coherent story. I am perfectly fine with it like that in my head, but I did start working on a more cohesive way of presenting his story as I began incorporating his story with my other storylines</p>
    </div>

    <div class="gallery">

        <h2>Gallery</h2>

        <img src="http://i.postimg.cc/RhPD46xg/Take-Me-Higher.png"><img src="http://i.postimg.cc/FzdbJYmQ/Tales-Of-My-Own-Sin.png"><img src="http://i.imgur.com/iHplfmg.png">

    </div>
</template>


