import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import Kira from '@/views/Skanerrak/Characters/Kira.vue'
import Steffen from '@/views/Skanerrak/Characters/Steffen.vue'
import Ander from '@/views/Skanerrak/Characters/Ander.vue'
import Takanya from '@/views/Skanerrak/Characters/Takanya.vue'
import Dela from '@/views/Skanerrak/Characters/Dela.vue'
import Will from '@/views/Skanerrak/Characters/Will.vue'
import Annie from '@/views/Skanerrak/Characters/Annie.vue'
import Skanerrak from '@/views/Skanerrak/Skanerrak.vue'
import Characters from '@/views/Skanerrak/Characters.vue'
import History from '@/views/Skanerrak/History.vue'
import Geography from '@/views/Skanerrak/Geography.vue'
import Terminology from '@/views/Skanerrak/Terminology.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    meta: { title: 'Escaiedail - Home' },
    component: HomeView
  },
  

  {
    path: '/Skanerrak',
    name: 'Skanerrak',
    meta: { title: 'Escaiedail - Skanerrak' },
    component: Skanerrak
  },

  {
    path: '/Skanerrak/History',
    name: 'History',
    meta: { title: 'Escaiedail - Skanerrak' },
    component: History
  },

  {
    path: '/Skanerrak/Geography',
    name: 'Geography',
    meta: { title: 'Escaiedail - Skanerrak' },
    component: Geography
  },


  {
    path: '/Skanerrak/Terminology',
    name: 'Terminology',
    meta: { title: 'Escaiedail - Skanerrak' },
    component: Terminology
  },


  {
    path: '/Skanerrak/Characters',
    name: 'Characters',
    meta: { title: 'Escaiedail - Skanerrak' },
    component: Characters
  },
  {
    path: '/Skanerrak/Characters/Kira',
    name: 'Kira',
    meta: { title: 'Escaiedail - Skanerrak' },
    component: Kira
  },

  {
    path: '/Skanerrak/Characters/Steffen',
    name: 'Steffen',
    meta: { title: 'Escaiedail - Skanerrak' },
    component: Steffen
  }
  ,

  {
    path: '/Skanerrak/Characters/Ander',
    name: 'Ander',
    meta: { title: 'Escaiedail - Skanerrak' },
    component: Ander
  }
  ,

  {
    path: '/Skanerrak/Characters/Takanya',
    name: 'Takanya',
    meta: { title: 'Escaiedail - Skanerrak' },
    component: Takanya
  }
  ,

  {
    path: '/Skanerrak/Characters/Dela',
    name: 'Dela',
    meta: { title: 'Escaiedail - Skanerrak' },
    component: Dela
  }
  ,

  {
    path: '/Skanerrak/Characters/Will',
    name: 'Will',
    meta: { title: 'Escaiedail - Skanerrak' },
    component: Will
  }
  ,

  {
    path: '/Skanerrak/Characters/Annie',
    name: 'Annie',
    meta: { title: 'Escaiedail - Skanerrak' },
    component: Annie
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0, left: 0 }
  }
})

router.beforeEach((to, from, next) => {
  console.log(to);
  document.title = to.meta.title;
  next();
});

export default router
