<template>

<nav>
    <ul>
        <li><router-link to="/Skanerrak">Skanerrak</router-link></li>
        <li><router-link to="/Skanerrak/History">History</router-link></li>
        <li><router-link to="/Skanerrak/Geography">Geography</router-link></li>
        <li><router-link to="/Skanerrak/Terminology">Terminology</router-link></li>       
        <li><router-link to="/Skanerrak/Characters">Characters</router-link></li>
        <li><a>Stories</a></li> |
        <li><router-link to="/">Home</router-link></li>
    </ul>
  </nav>
<div class="top"></div>


<div class="blurb">

  <h2>Gods</h2>

  <hr>
<h2>Spirits
</h2>

<p>All sentient creatures have a Spirit. The Spirit appears together with consciousness, shares the body with the consciousness, and, in most cases, dissipates with the consciousness as the body dies. Spirit-based magic, including Psionic powers, connect the consciousness or "will" of an individual with the material world. Because of this, the Spirit serves as an interesting middle ground between the body, which is connected with the rest of the material world, and the consciousness, which is entirely independent and unique to each individual. </p>

<p>Although each person's Spirit is uniquely their own, they may be influenced by others if there is a strong enough bond between them. This bond is most often described as a Psionic interaction by later humans, although it is not entirely accurate, as it can occur independent of Psionics. The results of this mutual influence include increasing Psionic powers, behavioral and physical resemblance, and a shared intuition for perils and danger.</p>

<p>Like anything else remotely related to Psionics, in the Kingdom of Skanerrak, the secrets of Spirits and their interactions are well kept from all but the royal family and the ancient race they descended from. In fact, the royal "family" itself is a family based heavily on Spirit interactions rather than pure blood/genetic relationships. In ancient times especially, each branch of the royal family would have a chosen heir, who the family would thoroughly develop emotionally and Psionically. The rest of the children, who were not raised to be the heir and were unaware of these secrets, would lose many of the phenotypic traits that define the royal family (heightened Psionic strength and their signature silvery hair and pale eyes) within a generation or two. It was also recorded that even children who were not related to the royal family by blood can be developed as such, and there were several instances where the "bloodline" was inherited by an outsider who was raised to be a royal heir. These records are buried deep within the many records of the royal family and have been a secret to almost everyone, including the modern royal family itself. As a result, even though the family has suffered in recent years and is dying out, there have been no real talk of adopting a child to inherit the throne as their ancestors would have done.</p>

<p>When a Spirit dissipates with the death of the individual, it does not entirely disappear. Instead, it builds into the World Spirit, an intricate web of the Multiverse that cannot be comprehended by humans. In a sense, the will of each individual Spirit contributes to the flow of events across the Multiverse, and no individual is truly lost. </p>

<h3><span>Spirit Animals</span></h3>

<p>Usually, Spirits cannot be directly observed by humans. When two individuals share a close Spiritual connection, however, they may "sense" the presence of each other's Spirit. Although Spirits are immaterial and do not have a real fixed material form, the sentient brain is trained to recognize what they sense as what they know. In the case of humans, Spirits are often sensed to be in the forms of animals, hence the colloquial name, Spirit Animals.</p>


<h3><span>Lone Spirits</span></h3>



<p>When the body dies, in rare instances, the Spirit will not immediately dissipate with the consciousness. This happens when the Spirit feels an extremely strong desire to remain behind and has enough strength to do so. Love and vengeance, being two of the strongest emotions a human can have, are the most common causes for Spirits to remain behind. If the Spirit is strong enough, other, weaker driving forces may also be enough. These Spirits are not protected by a body or consciousness and are therefore referred to as lone Spirits.</p>

<p>While the Spirit may remain without a consciousness or a body, it is incapable of directly interfering with the material world and may only interact with other Spirits, including those of living individuals. In the vast majority of cases, human Spirits are sheltered so well by the body and consciousness that it is inaccessible to lone Spirits. However, in situations where the body or consciousness is weak, such as near-death scenarios, lone Spirits may be able to access and "converse" with the Spirit of a living creature. Because the consciousness is not involved, however, the living creature in question may not be able to recall in detail these encounters.</p>

<p>Another unique scenario where a lone Spirit may interact with a living individual is when the consciousness is newly formed: that is, when new life is first conceived. At this time, the consciousness protecting the newly formed Spirit is weak, and a strong lone Spirit may take over. However, the consciousness cannot support Spirits that are much more developed than the original. This means, for a lone Spirit to take over a newly developed consciousness and survive, the lone Spirit itself must be very young. This is exceedingly rare but when it happens, the resulting individual is known as a Spirit Child. Spirit Children tend to have stronger Psionic powers than their peers due to their slightly more mature Spirit. They also tend to strike a sense of deja vu in others, especially those related to the Spirit's origin. These observations are the only clues that an individual might be a Spirit Child, for with extremely few exceptions, it is impossible to confirm that this is the case.</p>


<hr>

<h2>Free and Material Energy
</h2>

<hr>

<h2>Psionics
</h2>



</div>







</template>