<template>
    <nav>
    <router-link to="/">Home</router-link>
   
  </nav>
  <div class="home">
    <div class="blurb">
      This page is currently under major construction. Everything here is subject to frequent changes until I make up my mind about what I want to do.
    </div>
  </div>
</template>

