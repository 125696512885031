<template>
<nav>
    <ul>
        <li><router-link to="/Skanerrak">Skanerrak</router-link></li>
        <li><router-link to="/Skanerrak/History">History</router-link></li>
        <li><router-link to="/Skanerrak/Geography">Geography</router-link></li>
        <li><router-link to="/Skanerrak/Terminology">Terminology</router-link></li>       
        <li><router-link to="/Skanerrak/Characters">Characters</router-link></li>
        <li><a>Stories</a></li> |
        <li><router-link to="/">Home</router-link></li>
    </ul>
  </nav>
  <div class="top"></div>


    <div class="blurb">
        <h2>Takanya</h2>


<p class="text-center">

Left in the ruins of greatness<br>
And I'll keep their nightmare alive<br>
Fighting in vain<br>
We are the fiends in the shadows<br>
And I am the beast that survived<br><b>Here Come the Ravens, Aviators</b></p>



    </div>

    <div class="fluid-boxes">
      <div class="box">
        <p><b>Birthplace: </b>A small town near Istaldea<br><b>Birthday: </b>January 6th<br><b>Likes: </b>Family, navigating social networks<br><b>Dislikes: </b>Being betrayed<br><b>Strength: </b>Manipulating people<br><b>Weakness: </b>Lack of self awareness<br><b>Alignment: </b>Chaotic Neutral<br><b>Symbolic elements: </b>Purple/gold
        </p>
      </div>



      <div class="box">
        <p><b>Physical Strength: </b>4/10<br><b>Psionic Strength: </b>6/10<br><b>Intelligence: </b>10/10<br><b>Constitution: </b>6/10 <br><b>Charisma: </b>9/10<br><b>Perception: </b>8/10<br><b>Courage: </b>10/10 <br><b>Impulsiveness: </b>5/10 <br><b>Seriousness: </b>7/10 <br><b>Compassion: </b>3/10</p>
      </div>
    </div>

    <div class="blurb">
        <h2>Random Fact</h2>
<p>



</p>

<hr><h2>Relations</h2>
<ul class="list"><li><h2>Lukas</h2></li>
<li><h2>Kira</h2></li>
<li><h2>Estrid</h2></li>
<li><h2>Leon</h2></li>
<li><h2>Mara</h2></li>
</ul><hr><h2>Appearance</h2>
<div class="ref">
<h2><span>Human Form</span></h2>
<img src="../../../assets/portraits/Takanya.png"><p>
<b>Build: </b>Average height and somewhat frail, with slightly puffy cheeks; tanned skin<br>
<b>Hairstyle: </b>Long with large curls<br>
<b>Hair color: </b>Golden<br>
<b>Eye color: </b>Violet<br>
<b>Clothing: </b>Fairly casual with a preference for dark, muted colors. Avoids skirts/dresses if she could.<br>
<b>Accessories: </b>Has a chain of very extravagant and excessive bracelets that she likes to wear.
<br class="clr"></p></div>



<div class="ref">
<h2><span>Spirit Form</span></h2>
<img src="http://i.imgur.com/2QpyyuI.png"><p>

</p><p>
General form is a golden eagle with furry, Eyrie ears and collar, membranous wing "feathers", and three long tail feathers.</p><p>
Violet eyes</p><p>
Beak is less reddish than the body, but has a reddish patch.</p><p>
Tail feathers and wings are purple/blue; the purple/blue bits are semi-transparent.
</p>
<br class="clr"></div>






<hr><h2>Character Inspiration</h2>
<p>
I once had a dream about "me"/my PoV character hiding away in a bubble tea shop escaping some kind of persecution, until she realized that there was nothing to fear from simply talking to whatever was pursuing her and joining their cause. This eventually became the backbones of the story of Takanya's initial encounter with Lukas (Psi Hounds included, they were part of the dreams too, on the roof, somehow). Her early family story was based on my vague recollections of the backstory of a character from the mobile game Chain Chronicle. </p>
<p>For a very long time I did not know what to do with Takanya in the bigger story. She and Lukas were always meant to be the third faction in this free-for-all brawl, but exactly what role she would play, especially in the "end game", was very unc<a></a>lear to me. As more characters were developed, I had this tentative idea of her eventual semi-alliance with Kira as they shared similar sentiments regarding the Psionic Network. It was not until very recently, when I fully figured out the "ending" to the story, that she truly got her own place in the story.


</p>

    </div>

    <div class="gallery">

        <h2>Gallery</h2>

        <img src="http://i.imgur.com/IJ3vPQz.jpg"><img src="http://i.imgur.com/N16yeVb.png"><img src="http://i.imgur.com/f6svGZr.jpg">

    </div>
</template>


