<template>
<nav>
    <ul>
        <li><router-link to="/Skanerrak">Skanerrak</router-link></li>
        <li><router-link to="/Skanerrak/History">History</router-link></li>
        <li><router-link to="/Skanerrak/Geography">Geography</router-link></li>
        <li><router-link to="/Skanerrak/Terminology">Terminology</router-link></li>       
        <li><router-link to="/Skanerrak/Characters">Characters</router-link></li>
        <li><a>Stories</a></li> |
        <li><router-link to="/">Home</router-link></li>
    </ul>
  </nav>
  <div class="top"></div>

    <div class="blurb">
        <h2>Dela, Master of Dimensions</h2>



<p class="text-center">


When the silence falls at last<br>
And the clock tower rings no more<br>
We'll watch the hourglass<br>
Trapped like we were before<br>
And we'll lay awake in fear<br>
Of the past days come again<br>
But they'll never find us here<br>
Before they burn at this world's end<br><b>They'll Never Find Us, Aviators</b></p>

    </div>

    <div class="fluid-boxes">
      <div class="box">
        <p><b>Likes: </b>Moments of peace<br><b>Dislikes: </b>Fate<br><b>Strength: </b>Highly intuitive and imaginative<br><b>Weakness: </b>Lacks empathy for people outside her immediate circle<br><b>Alignment:</b> Chaotic Neutral<br><b>Symbolic elements: </b> Dripping light
        </p>
      </div>


      <div class="box">
        <p><b>Physical Strength: </b>6/10<br><b>Psionic Strength: </b>N/A<br><b>Intelligence: </b>7/10<br><b>Constitution: </b>7/10 <br><b>Charisma: </b>6/10<br><b>Perception: </b>10/10<br><b>Courage: </b>9/10 <br><b>Impulsiveness: </b>8/10 <br><b>Seriousness: </b>8/10 <br><b>Compassion: </b>4/10</p>
      </div>
    </div>

    <div class="blurb">
        <h2>Random Fact</h2>
<p>
Dela's pocket dimensions were a vague reference to one of my favorite quotes, from the book The Amber Spyglass:
</p>
<p style="font-style: italic;">
"Will you be confined to one world, as we are?"<br>
"No, we have other ways of traveling."<br>
"The way you have, is it possible for us to learn?"<br>
"Yes. [...] It uses the faculty of what you call imagination. But that does not mean making things up. It is a form of seeing."<br>
"Not real traveling then. Just pretend..."<br>
"No, nothing like pretend. Pretending is easy. This way is hard, but much truer."<br></p>
<p>
Because it is inherently tied to imagination, Myra was able to track down Dela in her pocket dimensions early on, mostly due to her familiarity with Dela and the way her imagination works, both as her teacher and as a proficient pediatric psychiatrist. This gradually changed as she matured, but because their initial haven was built in a very early dimension, it would always require additional magic from Will (and later Annie) to be fully secure.
</p>
<p>Even though she wanted to on multiple occasions, she never made an attempt to create a pocket dimension outside of Time. She was confident that it would be possible, but by that time (heh) she knew enough about the fabric of the Multiverse to know that it would be a bad idea. Probably.
</p>

<hr><h2>Relations</h2>
<ul class="list"><li><h2>Will</h2>Bound by blood, Spirit, and perhaps something more, Dela treasures <b>Will</b> as the purpose of her life. They have had their fair share of conflicts over the years, but being extremely in tune with each other's emotions, they see each other's presence as the ultimate comfort.  </li>
<li><h2>Annie</h2> Dela and <b>Annie</b> are childhood best friends, and continue to be so for eternity. Dela often felt guilty about involving Annie in their conflicts and often putting her in danger, and cherished the increasingly rare moments that they could afford to spend together to simply enjoy life as it is. </li>
<li><h2>Myra</h2> Despite being put on opposite sides of conflict by Fate, Dela distantly admires her first teacher <b>Myra</b> and her resourcefulness, determination, and persistence. Maybe that was where she picked up those traits from…</li>
<li><h2>Cyla</h2> Although far from the first mortal to step foot in Dela's pocket dimensions, <b>Cyla</b> caught her eye due to her ability to traverse the Multiverse without external help. What initially began as a scientific curiosity turned into a genuine friendship, and Cyla's watchful eye brought Dela much peace, knowing that Will's Spirit was still out there.</li>

<li><h2>Loteslea</h2> Ever since finding out about his existence, Dela has sought to confront <b>the World Leviathan</b> and either break the chains that bind him, or slay him and the entire Multiverse with him. This would be her quest for the rest of Eternity.</li>

</ul><hr><h2>Appearance</h2>
<div class="ref">
<h2><span>Human Form</span></h2>
<img src="../../../assets/portraits/Dela.png">
<p>
<b>Build: </b>Relatively tall. Pale skin glows slightly, especially at the fingertips.<br><b>Hairstyle: </b>Waist length hair, occasionally worn in a high ponytail (mostly when younger). <br><b>Hair color: </b>black<br><b>Eye color:</b> bright blue, slightly glowing<br><b>Clothing: </b> <br><b>Accessories: </b>Wears gloves to hide her hands' glow if necessary, but it does not often happen.
<br class="clr"></p></div>



<div class="ref">
<h2><span>Spirit Form</span></h2><img src="http://i.postimg.cc/xd8L61cx/DelaBirb.png"><p>
Usually depicted as a black bird with light dripping at the tips of the wings (five finger feathers) and tails (four tail feathers of different lengths). The light is neon yellow by default, but can depend on the environment. The body is an opaque, black, matte, smoky texture, rather than feathery like a real bird, but the wings do have feather textures and slight reflectivity. </p>
<p>
Alternatively, can appear as any creature with the same wings and tails and texture, depending on the situation and emotions involved. <strike>She is practically a god with limitless Spirit energy, her Spirit can be whatever she wants.</strike></p>

<br class="clr"></div>




<hr><h2>Character Inspiration</h2>
<p>My very first OC! The concept was originally from a character in a dream I had when I was 6 or 7. Over the years this character went from a typical "secret friend" to some sort of self insert, and eventually to a more fleshed out character as a more mature story began to form. For a long time, Dela has been my outlet to express some of my "darker" thoughts, and for quite a while she was almost more of a villain in the story. As I grew older and the story became more complex, she too became a more complex and realistic character (as realistic as a dimension-traveling god-of-light can be, lmao), although she had always been defined by her rather ferocious way of defending what she cared about.</p>
<p>
She was named after Della from "The Gifts of the Magi", which just so happened to be a short story we read in class at the time when I was fleshing out some of these stories, and I happened to really like the name.



</p>
    </div>

    <div class="gallery">

        <h2>Gallery</h2>

        <img src="http://i.postimg.cc/SxjFgYSj/They-ll-Never-Find-Us.png">

    </div>
</template>


