<template>

<nav>
    <ul>
        <li><router-link to="/Skanerrak">Skanerrak</router-link></li>
        <li><router-link to="/Skanerrak/History">History</router-link></li>
        <li><router-link to="/Skanerrak/Geography">Geography</router-link></li>
        <li><router-link to="/Skanerrak/Terminology">Terminology</router-link></li>       
        <li><router-link to="/Skanerrak/Characters">Characters</router-link></li>
        <li><a>Stories</a></li> |
        <li><router-link to="/">Home</router-link></li>
    </ul>
  </nav>
<div class="top"></div>
<div class="blurb">
<h2>The Isles of Skanerrak</h2>
<center><img src="http://i.imgur.com/aHy2Ecb.png" style="width: 90%;"></center><p>Skanerrak is a small kingdom located in the far north of an archipelagic planet. The Kingdom consists of four main islands (Hegoalzen, Ipparazen, Ekialzen, and Mendebalzen) and a small island chain (the Urrunen Islands). 

</p>
<p><b>Hegoazen</b>, located in the southwest, is home to the national capital <b>Egukizar</b> and the political center of the nation. Due to being further south than most of the country, Hegoazen is relatively warm, especially in areas south of the mountain ranges that occupy the center of the island. Much of the southern parts of Hegoazen is covered with deciduous forests and marshes, in stark contrast to the rest of the country, where evergreens are everywhere and the coasts and river banks are either barren or frozen (or built into cities).</p>

<p><b>Ipparazen</b> is the largest of the Skanerrak islands. Two large mountain ranges split the island into two parts: the southern part where the majority of the island's population lives, and the northern parts, where the only sign of civilization are certain industries and small groups of natives thanks to the hostile climate. Two large rivers run in the south. One of them, running from <b>Ibiak</b> westward toward <b>Menditsas</b>, flows through the most populated area of the whole nation and is often known as the artery of Skanerrak. Ipparazen's twin capital cities, <b>Mibai</b> and <b>Eibai</b>, sit on the west and east side of the other river mouth and are a constant reflection of the island's conflicted and complicated history. To the east is the city of <b>Istaldea</b>, the largest city in the autonomous region controlled by <a href="#anarchy">the Anarchy</a>.</p>


<p><b>Ekialzen</b> is the second largest island of Skanerrak. Its capital <b>Haitzur</b> lies at the gates of the <b>Psionic Vault</b>, a cave fabled to store tremendous amounts of Psionic energy but has, in modern times, been developed as a tourist attraction. Ekialzen is more mountainous than Hegoazen or Ipparazen and the climate is much colder and icier. As a result, the population is a lot smaller, and the people there have some unique traditions not observed in other parts of the nation, due to being relatively isolated for large periods of history. Parts of the western regions of Ekialzen are controlled by the Anarchy.</p>


<p><b>Mendebalzen</b>, located to the far northwest, is the smallest of the main islands. It is the historical home of the ancient race from which the modern royal family is descended. Mendebalzen suffers from extreme climate year long due to being close to the north pole, and population is scarce. The island, however, is home to many historical relics and sites. Most notably, at the center of the island in what appears to be a volcanic crater, <b>Lake Izobia</b> feeds into the Ice Tombs, where members of the royal family are traditionally laid to rest. There are also various Psionics-related sites scattered throughout the island, and rumor has it that the Spirits of the three founders of Skanerrak are watching over the kingdom from their home in Mendebalzen to this day.</p>

</div>
  </template>

