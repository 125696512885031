<template>
  <router-view/>
</template>

<style>
nav {
	top: 0;
	width: 100%;
	text-align: center;
	z-index: 999;
	position: fixed;
		background: #323a3f;
		font-family: "Century Gothic",CenturyGothic,AppleGothic,Tahoma,sans-serif;
	font-style: normal;
	font-weight: 100;
	text-transform: uppercase;
}

nav a:link {
	text-decoration: none;
}
nav li a {
	color: #fff;
	letter-spacing: 1pt;

	display: inline-block;
}
nav a:hover {
	color: #9aa1a3;
}
nav li {
	margin: 0 22px;
	display: inline-block;
	padding: 4px 0;
	list-style: none;
}
nav li span {
	background: #323a3f;
	display: none;
	position: absolute;
}
nav li:hover span {
	display: block;
	width: 170px;
}
nav li span a {
	display: block;
	padding: 10px 20px;
	color: #e7e7e7;
	text-align: left;
	text-transform: none;
	font-size: 1rem;
}
</style>