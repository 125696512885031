<template>
<nav>
    <ul>
        <li><router-link to="/Skanerrak">Skanerrak</router-link></li>
        <li><router-link to="/Skanerrak/History">History</router-link></li>
        <li><router-link to="/Skanerrak/Geography">Geography</router-link></li>
        <li><router-link to="/Skanerrak/Terminology">Terminology</router-link></li>       
        <li><router-link to="/Skanerrak/Characters">Characters</router-link></li>
        <li><a>Stories</a></li> |
        <li><router-link to="/">Home</router-link></li>
    </ul>
  </nav>

<div class="top"></div>
<div class="blurb">

<h2>The Gods of the Multiverse</h2>

    <div class="char-box">
    <a>
    <div class="character">
    <h2>Loteslea</h2>
    <hr>
    <p>The God of Order, Balance, and Destiny, also known as the Chained One, or the Body of the Multiverse. Patron God of physicists, maintainer of all law, determiner of all fates, and sees to it that the Balance of the Multiverse is never broken. </p>
    </div>
    </a>

    <a>
    <div class="character">
    <h2>Katasuna</h2>
    <hr>
    <p>The God of Chaos, Freedom, and Entropy, also known as the Free One, or the Mind of the Multiverse. The source of true randomness and spontaneity in the Multiverse, brings about change and progress in a world that is otherwise doomed to stagnate. </p>
    </div>
    </a>

    <a>
    <div class="character">
    <h2>Skanerrak</h2>
    <hr>
    <p>The Spirit of the Multiverse, also known as the Silent One. Does not directly govern the Multiverse, but is the source of all Spirits, and by extension, the source of all Life. </p>
    </div>
    </a>

    </div>
    <hr>


<h2>Pre-Psionics Era</h2>

    <div class="char-box">
    <router-link to="/Skanerrak/Characters/Dela">
    <div class="character">
    <h2>Dela</h2>
    <hr>
    <img src="../../assets/portraits/Dela.png">
    <p>The older twin sister to Will, she was made immortal by the God of Chaos as a baby in a spontaneous moment and gained the ability to harness the free energy of the Multiverse with her Spirit. She treasures her brother above all else and is willing to try and slay a God to break his inevitable fate. She is a master of imagination and pocket dimensions, often using both to protect her brother from the world.</p>
    </div>
    </router-link>
    
    <router-link to="/Skanerrak/Characters/Will">
    <div class="character">
    <h2>Will</h2>
    <hr>
    <img src="../../assets/portraits/Will.png">
    <p>Along with his twin sister Dela, he too received the ability to harness free energy with his Spirit, but his unique circumstance caused a time anomaly and broke the unbreakable balance of the Multiverse. As a result, the underlying forces of the Multiverse seeks his destruction. Seeing no permanent solution to his predicament, he allowed his physical body to take on an immaterial form, letting his Spirit and its energies freely disperse across the Multiverse. </p>
    </div>
    </router-link>

    <router-link to="/Skanerrak/Characters/Annie">
    <div class="character">
    <h2>Annie</h2>
    <hr>
        <img src="../../assets/portraits/Annie.png">
        <p>Childhood best friends with Dela, she helped her reunite with Will and embarked on her otherworldly adventures as a mortal human. Having no magic powers of her own, she instead focused on gaining knowledge and understanding their enemies. She correctly deduced the existence of the Gods and sought them out for more answers, only to be spontaneously given control of material energy by the God of Balance.</p>
    </div>
    </router-link>

    <a>
    <div class="character">
    <h2>Myra</h2>
    <hr>
    <img src="../../assets/portraits/Myra.png">
    <p>A local school teacher in a war-ridden village, Myra was grieving for her father when she found two starving babies in a ransacked house. Unbeknownst to her at the time, the children were given Spirit powers by the God of Chaos, and she herself had been given an antagonistic Spirit power by the God of Balance in response. As the children grew older, so too did their inevitable conflict.</p>
    </div>
    </a>

    <a>
    <div class="character">
    <h2>Riley</h2>
    <hr>
    <img src="../../assets/portraits/Riley.png">
    <p>The eldest of four kids in a time of war, he blamed himself for losing sight of his youngest sister as the family attempted to flee their besieged village. The guilt turned him to a desperate quest to find his sister and attracted the attention of the God of Chaos. Now with control of material energy, he found out that his sister's fate was much more complicated than he had thought, and that he would be destined to be part of it.</p>

    </div>
    </a>

    <a>
    <div class="character">
    <h2>Stewart</h2>
    <hr>
    <p>A loving single father to Myra until the ongoing war took his life. His Spirit remained with his daughter after death, allowing him to witness her conflict with Dela and Will and her eventual defeat. His Spirit was later recovered by Riley, and he gave it both his material magic and Myra's Spirit powers to become a vengeful abomination. </p>
    </div>
    </a>





    </div>

<hr>
<h2>Modern Skanerrak</h2>

    <div class="char-box">

    <router-link to="/Skanerrak/Characters/Kira">
    <div class="character">
    <h2>Kira</h2>
    <hr>
    <img src="../../assets/portraits/Kira.png">
    <p>Growing up with no knowledge of her heritage, she was much more interested in learning more about Psionics than politics when she finally returned to Skanerrak. Together with unlikely allies, she sought to find and destroy the source of the cosmic invasions that had been happening across the Multiverse. In doing so, she eventually deduced the truth about the Psionic artifacts that the Monarchy had been hiding away, and the true nature of Psionics.</p>
    
   
    </div></router-link>
    
    <router-link to="/Skanerrak/Characters/Steffen">
    <div class="character">
    <h2>Steffen</h2>
    <hr>
    <img src="../../assets/portraits/Steffen.png">
    <p></p>
    </div>
    </router-link>

    <router-link to="/Skanerrak/Characters/Ander">
    <div class="character">
    <h2>Ander</h2>
    <hr>
    <img src="../../assets/portraits/Ander.png">
    <p>Having inherited his family business at a young age, he lived an isolated life filled with scrutiny and skepticism until he discovered and adopted Steffen, who quickly became a valuable asset to the business and a close personal friend. However, his hatred of anything Psionics and hot temper led to Steffen embarking on a one way journey to assassinate the Kingdom's Psion Queen. He spent the rest of his life trying to come to terms with everything that had happened.</p>
    </div>
    </router-link>

    <router-link to="/Skanerrak/Characters/Takanya">
    <div class="character">
    <h2>Takanya</h2>
    <hr>
    <img src="../../assets/portraits/Takanya.png">
    <p>A local criminal who had cut ties with her family, she was scouted and recruited by the leader of the Anarchy. There, she discovered the depth of the knowledge surrounding Psionics that the royal family had kept hidden for millenia, and inherited the quest to dissolve the Monarchy, by peace or by force. As her options began to run dry, an unexpected message from Kira led them down a road of discovery and eventual understanding.</p>
    </div>
    </router-link>

    <a>
        <div class="character">
    <h2>Mika</h2>
    <hr>
    </div>
    </a>

    <a>
        <div class="character">
    <h2>Nicola</h2>
    <hr>
    </div>
    </a>

    <a>
        <div class="character">
    <h2>Emmi</h2>
    <hr>
    </div>
    </a>

    <a>
        <div class="character">
    <h2>Karl</h2>
    <hr>
    </div>
    </a>

    <a>
        <div class="character">
    <h2>Anni</h2>
    <hr>
    </div>
    </a>

    <a>
        <div class="character">
    <h2>Erik</h2>
    <hr>
    </div>
    </a>

    <a>
        <div class="character">
    <h2>Lukas</h2>
    <hr>
    </div>
    </a>

    <a>
        <div class="character">
    <h2>Mara</h2>
    <hr>
    </div>
    </a>

    <a>
        <div class="character">
    <h2>Estrid</h2>
    <hr>
    </div>
    </a>

    <a>
        <div class="character">
    <h2>Leon</h2>
    <hr>
    </div>
    </a>


</div>

    <hr>
<h2>The Resistance</h2>

    <div class="char-box">

    <a>
    <div class="character">
    <h2>Basik</h2>
    <hr>
    </div>
    </a>

    <a>
    <div class="character">
    <h2>Dan</h2>
    <hr>
    </div>
        </a>

    <a>    
    <div class="character">
    <h2>Sophia</h2>
    <hr>
    </div>
    </a>

    <a>
    <div class="character">
    <h2>Sonali</h2>
    <hr>
    </div>
    </a>

    <a>
    <div class="character">
    <h2>Sakura</h2>
    <hr>
    </div>
    </a>

    <a>
        <div class="character">
    <h2>Willow</h2>
    <hr>
    </div>
    </a>


    </div>


    <hr>
<h2>Travelers</h2>

    <div class="char-box">

<a>
        <div class="character">
    <h2>Cyla</h2>
    <hr>
    </div>
</a>
    </div>


    <hr>
<h2>Historical Characters</h2>

    <div class="char-box">
<a>
    <div class="character">
    <h2>Miru</h2>
    <hr>
    </div>
    </a>

    <a>
        <div class="character">
    <h2>Rubina</h2>
    <hr>
    </div>
    </a>

    <a>
        <div class="character">
    <h2>Fran</h2>
    <hr>
    </div>
    </a>

    <a>
        <div class="character">
    <h2>Margareta</h2>
    <hr>
    </div>
    </a>

    <a>
        <div class="character">
    <h2>Markel</h2>
    <hr>
    </div>
    </a>

    <a>
            <div class="character">
    <h2>Remi</h2>
    <hr>
    </div>
    </a>

    <a>
            <div class="character">
    <h2>Aron</h2>
    <hr>
    </div>
    </a>


    </div>

    <hr>
    <h2>Friends, Relatives, and Companions</h2>

    <div class="char-box">

<a>
    <div class="character">
    <h2>Marianna</h2>
    <hr>
    </div>
    </a>

    <a>
    <div class="character">
    <h2>Lydia</h2>
    <hr>
    </div>
    </a>

    <a>

    <div class="character">
    <h2>Alfie</h2>
    <hr>
    </div>
    </a>

    <a>
    <div class="character">
    <h2>Annabel</h2>
    <hr>
    </div>
    </a>

    <a>
    <div class="character">
    <h2>Amina</h2>
    <hr>
    </div>
    </a>

    <a>
    <div class="character">
    <h2>Jonas</h2>
    <hr>
    </div>
    </a>

    <a>
    <div class="character">
    <h2>Timothy</h2>
    <hr>
    </div>
    </a>


    </div>


</div>
</template>

<style>
.char-box {
  display: flex;
  justify-content: flex-start;
  width: 100%; 
  margin: auto;
  flex-wrap: wrap;
  
}

.character {
  width: 380px;
  min-height: 300px;
  height: auto;
  border: 3px solid #ccc;
  margin: 20px;
  padding: 10px;
  border-radius: 1rem;
  background-color: #ddd;

}

.character:hover {
  border: 3px solid #aaa;
  background-color: #eee;
}

.character h2 {
  font-size: 1rem;
  color: #8d8f9b;
  font-weight: bold;
}

.character hr {
  margin: 0.5rem auto 1rem auto;
  border-color: #ccc
}
.character p {
  line-height: 120%;

}

.character img {
  float: left;
  height: 100px;
  width: 100px;
  margin-right: 10px;
  border-radius: 2em;
  border: 2px #ccc solid
}

.character a {
  font-weight: bold;
  font-family: "Century Gothic",CenturyGothic,AppleGothic,Tahoma,sans-serif;
  font-size: 1.5em;
}


@media only screen  and (max-device-width: 1000px) and (orientation: portrait) {
.character {
  max-width: 83vw;
  margin-left: -10vw;
}


}
</style>