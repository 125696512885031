<template>
<nav>
    <ul>
        <li><router-link to="/Skanerrak">Skanerrak</router-link></li>
        <li><router-link to="/Skanerrak/History">History</router-link></li>
        <li><router-link to="/Skanerrak/Geography">Geography</router-link></li>
        <li><router-link to="/Skanerrak/Terminology">Terminology</router-link></li>       
        <li><router-link to="/Skanerrak/Characters">Characters</router-link></li>
        <li><a>Stories</a></li> |
        <li><router-link to="/">Home</router-link></li>
    </ul>
  </nav>
  <div class="top"></div>

    <div class="blurb">
        <h2>Annie, A Mortal Ascended</h2>

<p class="text-center">
Where light shines above the abyss<br>
I'll pass through the darkening mists<br>
At the toll of the bells<br> 
I'll rise to face legends divine



<br><b>Let there be Fire, Aviators</b></p>
    </div>

    <div class="fluid-boxes">
      <div class="box">
        <p><b>Likes: </b>Books and nature<br><b>Dislikes: </b>Feeling helpless<br><b>Strength: </b>Attention to detail<br><b>Weakness: </b>Self doubt<br><b>Alignment:</b> Neutral Good<br><b>Symbolic elements: </b> Red and gold foliage/nature elements
        </p>
      </div>


      <div class="box">
        <p><b>Physical Strength: </b>5/10<br><b>Psionic Strength: </b>N/A<br><b>Intelligence: </b>10/10<br><b>Constitution: </b>6/10 <br><b>Charisma: </b>7/10<br><b>Perception: </b>8/10<br><b>Courage: </b>8/10 <br><b>Impulsiveness: </b>6/10 <br><b>Seriousness: </b>7/10 <br><b>Compassion: </b>10/10</p>
      </div>
    </div>

    <div class="blurb">
        <h2>Random Fact</h2>

<p>

In essence, the abilities that Dela gave to Annie before her true ascension are the same as modern Psionics. Both powers are based on Spirit energy, where a Spirit gains enough energy from external sources to allow the consciousness to manipulate their surroundings, independent of the body. Because her power came from a pure source of energy (as opposed to modern Psions, whose energy came from fragments of Will's Spirit which was inherently interlaced with anti-energy), the glow that was associated with her use of the power was pure white, unlike the purple that is often associated with Psionics. This also meant that Annie technically did also have a Spirit Animal. Unlike her material transformation, her Spirit form is a large white swan and often associated with comfort and healing. 
</p>
<p>
This form of Spirit power does not inherently grant passive immortality (although Dela or Will would have the ability to actively save her from death, if necessary). For the majority of her adventures with and against beings with godly powers, Annie remained a true mortal.
</p>
<hr><h2>Relations</h2>
<ul class="list"><li><h2>Dela</h2></li>
<li><h2>Will</h2></li>
<li><h2>Myra</h2></li>
<li><h2>Riley</h2></li>
<li><h2>Katasuna</h2></li>




</ul><hr><h2>Appearance</h2>
<div class="ref">
<h2><span>Human Form</span></h2>
<img src="../../../assets/portraits/Annie.png">
<p>
<b>Build: </b>Average height, light freckles. <br><b>Hairstyle: </b>Long straight hair, often with thin braids running through it<br><b>Hair color: </b>Red<br><b>Eye color:</b> Green<br><b>Clothing:</b> <br><b>Accessories: </b>Sometimes wears a flower hair pin<br class="clr"></p></div>

<div class="ref">
<h2><span>Spirit Form</span></h2>
<p>A large white swan. Rarely represented in this form.</p>

<br class="clr"></div>

<div class="ref">
<h2><span>Transformation</span></h2>
<p>A golden, antlered deer with slightly glowing spots and eyes. Flowers and vines grow over the antlers. Has wings of webbed branches that are sometimes flowering. Turns her immediate surroundings into brightly colored vines and flowers.</p>
<p>This is a physical transformation associated with her material-based (rather than Spirit-based) powers. This form is solid and has material substance, rather than the immaterial forms of those associated with Spirit-based powers (including Psionics).</p>


<br class="clr"></div>




<hr><h2>Character Inspiration</h2>
<p>
Annie was originally very much inspired by Ginny from the Harry Potter BOOKS, both in terms of appearance and personality. She was originally meant to be a companion that Dela and Will met in their teenage years, but that had since changed as their entire early story got reworked. Nevertheless, her role in the story remains the same.</p>

<p>
Her importance in the story would fluctuate depending what aspects I was focused on developing at the time, but the most significant change was when (for reasons that I cannot remember) I decided to take her out of the main story for a while. This eventually evolved into her pursuit of ascension that occurred in a much more logical way. 
</p>
    </div>

    <div class="gallery">

        <h2>Gallery</h2>

        <img src="http://i.imgur.com/ohNaulZ.jpg">

    </div>
</template>


