<template>

<nav>
    <ul>
        <li><router-link to="/Skanerrak">Skanerrak</router-link></li>
        <li><router-link to="/Skanerrak/History">History</router-link></li>
        <li><router-link to="/Skanerrak/Geography">Geography</router-link></li>
        <li><router-link to="/Skanerrak/Terminology">Terminology</router-link></li>       
        <li><router-link to="/Skanerrak/Characters">Characters</router-link></li>
        <li><a>Stories</a></li> |
        <li><router-link to="/">Home</router-link></li>
    </ul>
  </nav>
<div class="top"></div>

<div class="blurb">
<h2>The Kingdom of Skanerrak
</h2>



<p>No one is quite sure where the ancient race that inhabited the islands of modern day Skanerrak over 2000 years ago came from. They were human, as far as anyone could tell, but that was the only conclusion that historians, anthropologists, evolution biologists, and archaeologists could agree on. The islands were located in the far north of the planet, separated from other landmasses by freezing climate and vast oceans, and as a result, they lived the land in their own unique way, much different from the progress of civilization that was beginning to blossom Elsewhere.</p>

<p>What was ultimately unique about this race was that they appeared to be extremely gifted in the Spirit-based power that modern humans would call Psionics. Not "gifted" in the modern sense - being able to accomplish more strenuous feats easier - but more that they seemed to know more about the intricate mechanisms of this unique power than any modern day Psion. They were aware, for example, of the existence of Spirits and their role in Psionics. They had records of alternative dimensions and the presence of the Multiverse. They knew about the existence of the World Spirit, the combined existence of Spirits across the Multiverse that shapes the worlds to its will. </p>

<p>And yet, even with such extensive knowledge, there was very little evidence that they practiced Psionics themselves, aside from using it for communication. They appeared to be observers and researchers, dipping into the unknown, admiring it, but never reaping the harvest. If they had, things might have turned out very differently for them, and the entire history of Skanerrak might have to be rewritten (or as some would say, not written at all).</p>


<h3><span>The Birth of the Kingdom</span></h3>
<p>As civilization developed Elsewhere on the planet and naval technology reached new breakthroughs, an adventurous foreign princess by the name of Rubina decided to embark upon a journey to sail as far north as she could. After drifting in the oceans for weeks, she finally managed to reach solid ground - the island that was later known as Mendebalzen. </p>

<p>The natives took her in. Though they spoke different languages, they were able to communicate, thanks to Psionics. At the time, her ship was in poor condition, she was out of food, her crew was sick and dying, and she had no realistic means to return home. Being an adventurer at heart, Rubina did not find her situation to be too terrible - there were still people who cared for her, at the very least.</p>

<p>Years passed. Rubina settled in gracefully into her new life. She learned their language, practiced their communal lifestyle, and started exploring their world of Psionics and Spirits with her mind. She had a daughter, whom she named Miru, and she would tell her eye-opening stories of foreign kingdoms and courts, and even teach her a tongue that no one else on the island understood. </p>

<p>Everything changed, however, when one day grand fleets appeared on the horizon. Rubina's home kingdom had finally mustered a naval force powerful enough to bring their lost princess home. She left with them immediately, leaving everything behind. She told herself that she would have taken Miru with her if situation permitted - the little girl was out exploring an ice cave at the time, and they wanted to leave at once to avoid drawing too much attention (plus she'd have to explain Miru's existence to everyone on the fleet, then back at home too). Whether or not that was true, however, only she would ever know.</p>

<p>Decades passed. Life up north continued as it always did. Elsewhere, Rubina eventually succeeded her father and became the new queen of her kingdom, and she had an ambitious plan - to claim the little island that she once visited as a young princess as part of her kingdom. This would give her control of the oceans between her mainland and the island and would more than double the size of her kingdom. Once again she set sail, but this time with battleships and weapons, "just in case".</p>

<p>The natives refused to answer to her authority (mainly because the concept of authority never existed to them), which she expected and was obviously prepared for. A war broke out, but it was more a massacre than a war, for the natives had no means to defend themselves against battle-tested weapons from Elsewhere.</p>

<p>And then, two things happened.</p>

<p>One, a group of natives - those who belonged to several clans that studied Psionics most intensively - decided to combine their strength and draw upon the power that they had long been studying. Tapping into the energy from not only their own bodies, but from the World Spirit as well, they were somehow able to collectively teleport out of the island, not to another location on the planet or in the universe, but across dimensions to a different universe altogether. There, safe from the invaders, they decided to hone their Psionic powers and eventually went down a completely different route of progress and "evolution" as their brethren.</p>

<p>Two, Miru. She grew up with Rubina's stories of the politics and battles from Elsewhere, and of all people native to the island, she was the only one to fully understand the situation. Thus she took action on her own, wielding the one weapon she had, the thing she knew that the kingdoms of Elsewhere loved and cherished - her own blood connection to the queen. </p>
<p>Using Psionics, she was able to identify two people in Rubina's party that she could potentially convince to join her. One was her bodyguard, Fran, who was young and battle-ready but had internal struggles regarding the morality of war. The other was her personal assassin Margareta, whom was given to her as a slave to be trained for this one purpose only. With their support, she was able to secretly approach her bedchambers in her fleet late at night and end the war personally with a silver knife. Then she, who had both the blood of the foreign queen and the native race, claimed her rightful place on the throne. </p>

<p>With her two companions, she founded a kingdom on the northern islands and named it Skanerrak, which was the name of the ancient tribe, as well as the name that they gave to the World Spirit. She soon established her presence on the nearby islands, adding them to the map of Skanerrak. She was just as enthralled by Psionics as she was as a little kid, and spent the rest of her life pursuing its secrets. Notably, she founded the Psionic Vault, a secluded location where Psionic powers from Spirits weak and strong could be gathered. She also sealed Lake Izobia with a strong barrier to preserve the Spirits of those who would be buried underneath, establishing it as the burial site for her and her descendants. What exactly it was that drove her to accomplish these feats remained very much in debate, partly because Miru made the decision to keep most of what she knew about the native race and Psionics a secret. The only ones who were aware of these secrets were her companions, and later, her descendants who would rule over Skanerrak to this day. </p>




<h3><span>The Psionics Crusade</span></h3>
<p>The rate of naturally Psionic individuals have been much higher in Skanerrak than in other nations around the world, an observation largely (but inaccurately) attributed to the contributions of the ancient Psionic race to the genetic pool of the citizens of Skanerrak. This has led to many social conflicts between Psionic and non-Psionic individuals, and throughout history, many calls for banning Psionics, or attempts to find a "cure" for it, have been made.</p>

<p>The monarchy and the royal family are given the historical responsibility of safeguarding Psionics and its secrets. Their interpretation of that responsibility, for the most part, has been to hide it all in a dark cellar away from the public eye. This is why, come modern times, most people are not aware that the current royal family has anything to do with Psionics.</p>

<p>One particular king, however, did not like the prospect of hiding in a hole. Instead, he took it to the other extreme. Having no real political powers as the king in a modern political system, he instead mind-controlled key politicians and pushed a movement to brutally suppress anyone who opposes Psionics. Lots of blood were shed in the process, and many were executed for supposed treason - including Markel, the founder of the largest biotechnology company in the country. On top of it all, no one was aware that the king was behind it all. Even now, when reflecting upon those times, the general consensus was that the entire incident was an unfortunate result of bad choice of government leaders. </p>

<p>The years of darkness eventually passed, but not without its consequences. A young man named Aron stumbled across key evidence linking the king to the movement, and used it as blackmail to establish an autonomous region named the Anarchy that answers to no government and openly opposes the monarchy. Markel's descendants inherited his company and kept it flourishing, making sure to imprint a strong hate for Psionics upon every new generation. The royal family had, since then, suffered an inexplicable loss in numbers, where families would seldom have heirs, and accidents kept happening to them at an alarming frequency, almost as if they were being cursed. The public, meanwhile, became generally more aware and grudgingly more tolerant of Psionics, although where there was conflict, the scale had been higher than ever before.</p>

<h3><span>The Alien Invasion</span></h3>

<p>One day, roughly a century after the turmoil that was the Psionics Crusade ended, an alien aircraft landed on Ekialzen, the easternmost of the Skanerrak islands.</p>

<p>The people did not know where they came from. All they knew was that they demanded to know where the Psionic Vault was, and, when the local royal family, the guardians of the Vault, refused to give them an answer, they waged war against them, controlled the island, then - started doing their own (seemingly) peaceful research. What the people did not know was that these aliens were descendants of the same Psionic race who fled Skanerrak a long long time ago. They took pride in their mental powers and, influenced by the whispers of <a href="#Stiaerna">something much more sinister</a>, used it to evolve themselves to the point where they are barely anything more than their minds. Now that the time is ripe, they have come back to reclaim their ancestral home.</p>
<p>But they made one oversight. While pursuing the local leaders of the royal family, they let their daughters, Emmi and Anni, escape the island. And as the aliens turned their eyes to the rest of the planet, the girls have just sparked the beginning of a whole new story.</p> 

</div>

  </template>