<template>
<nav>
    <ul>
        <li><router-link to="/Skanerrak">Skanerrak</router-link></li>
        <li><router-link to="/Skanerrak/History">History</router-link></li>
        <li><router-link to="/Skanerrak/Geography">Geography</router-link></li>
        <li><router-link to="/Skanerrak/Terminology">Terminology</router-link></li>       
        <li><router-link to="/Skanerrak/Characters">Characters</router-link></li>
        <li><a>Stories</a></li> |
        <li><router-link to="/">Home</router-link></li>
    </ul>
  </nav>
  <div class="top"></div>


    <div class="blurb">
      <h2>Steffen</h2>


<p class="text-center">
Like a crutch you carry me without restraint<br>
Back to a place where I am not alone<br>
I'm a man whose tragedies have been replaced<br>
With memories tattooed upon my soul<br><b>Into You, Dead by Sunrise</b></p>

    </div>

    <div class="fluid-boxes">
      <div class="box">
        <p><b>Birthplace: </b>Menditsas<br><b>Birthday: </b>May 25th<br><b>Likes: </b>Reading<br><b>Dislikes: </b>Social occasions, being disturbed while working<br><b>Strength: </b>The ana<a></a>lytical side of his brain<br><b>Weakness: </b>Comprehending human relationships<br><b>Alignment:</b> Neutral<br><b>Symbolic elements: </b> Orange/black/gold, the sun/horizon
        </p>
      </div>



      <div class="box">
        <p><b>Physical Strength: </b>6/10<br><b>Psionic Strength: </b>2/10<br><b>Intelligence: </b>10/10<br><b>Constitution: </b>10/10 <br><b>Charisma: </b>3/10<br><b>Perception: </b>8/10<br><b>Courage: </b>10/10 <br><b>Impulsiveness: </b>5/10 <br><b>Seriousness: </b>9/10 <br><b>Compassion: </b>2/10</p>
      </div>
    </div>

    <div class="blurb">
        <h2>Random Fact</h2>
<p>Despite it being the eventual cause of both he and his mother's demise, the neurological condition that Steffen inherited may very well have saved his sanity in his multiple encounters with hostile Psionic forces. Much of the hostile Psionics encountered in this period of time relied on the link between the Spirit and the Consciousness, and Steffen's condition meant that this connection was less conductive, thus dampening any impact of such attacks, even without the ability to actively defend or counter-attack. On the flip side, it also meant he would struggle to leverage this connection and use Psionics himself outside of extreme circu<a></a>mstances. However, his time spent under the influence of the Black Box may have enhanced his Spirit, partially suppressing the effects (both positive and negative) of his condition. This would be due to the source of the Black Box's powers being partially transferred to Steffen himself over long term exposure.</p>
<p>This neurological condition is partially based on real life genetic prion diseases, just with a Psionic twist. </p>

<hr><h2>Relations</h2>
<ul class="list"><li><h2>Ander</h2></li>
<li><h2>Emmi</h2></li>
<li><h2>Karl</h2></li>
<li><h2>Kira</h2></li>
<li><h2>Mika</h2></li>
<li><h2>Nicola</h2></li>
<li><h2>Anni</h2></li>
<li><h2>The Psiborgs</h2></li>
<li><h2>Takanya</h2></li>

</ul><hr><h2>Appearance</h2>
<div class="ref">
<h2><span>Human Form</span></h2>
<img src="../../../assets/portraits/Steffen.png"><p>
<b>Build: </b>Tall and somewhat thin (but not overly so)<br>
<b>Hairstyle: </b>Medium straight hair<br>
<b>Hair color: </b>Brown<br>
<b>Eye color: </b>Dark brown/black<br>
<b>Clothing: </b>Likes wearing a hooded jacket or pullover in any style or color. Somewhat awkward and uncomfortable in formal clothes. <br>
<b>Accessories: </b>None</p>
<br class="clr"></div>







<hr><h2>Character Inspiration</h2>

<p>The original idea for Steffen's character came from a character I created for XCOM that was heavily inspired by Adam Jensen and the stories of Deus Ex: Human Revolution. The relationships between Jensen, Sarif, and Reed were the best kind of complicated, and inspired a lot of Steffen's personality and personal relationships as the story evolved further. Although this original character was never really used in any campaign or story, the human appearance and visual symbolism (orange-gold, sun, roses) carried over to Steffen's design.</p>
<p>Another, more story-oriented inspiration was Daud from Dishonored. Daud was perhaps my favorite video game character of all time, in no small part because he was distinctly him despite all the changes and character development. He had reasons to "play the villain", had reasons to seek some form of redemption, and had reasons to go on an otherworldly quest - and all of those reasons were because of his personality that stayed the same despite drastic changes in circu<a></a>mstances. Obvious story similarities aside, this was something that I very much wanted to express in Steffen. </p>
    </div>

    <div class="gallery">

        <h2>Gallery</h2>

        <img src="http://i.postimg.cc/qBCHtJNG/The-Ice-Cream-Has-Gone-Warm.png">
        <img src="http://i.postimg.cc/nrF434P8/Kira-Steffen-Sunset.png">
        <img src="http://i.imgur.com/gYGD4eB.png">
  
        <img src="http://i.imgur.com/YfxiH9c.png">
        <img src="http://i.imgur.com/brKUfL8.png">
       

    </div>
</template>