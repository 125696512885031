<template>
<nav>
    <ul>
        <li><router-link to="/Skanerrak">Skanerrak</router-link></li>
        <li><router-link to="/Skanerrak/History">History</router-link></li>
        <li><router-link to="/Skanerrak/Geography">Geography</router-link></li>
        <li><router-link to="/Skanerrak/Terminology">Terminology</router-link></li>       
        <li><router-link to="/Skanerrak/Characters">Characters</router-link></li>
        <li><a>Stories</a></li> |
        <li><router-link to="/">Home</router-link></li>
    </ul>
  </nav>
  <div class="top"></div>


    <div class="blurb">
      <h2>Kira</h2>

      <p class="text-center">
        I walk a wild new world, the strangest sights surround me<br />
        I grow into this sense of wonder that I've found<br />
        There is pain, there is joy<br />
        There is so much they destroy<br />
        Every soul here is a two way battleground<br />
        <b>The Moment, Miracle of Sound</b>
      </p>


    </div>

    <div class="fluid-boxes">
      <div class="box">
        <p>
          <b>Birthplace: </b>Elsewhere<br />
          <b>Birthday: </b>November 18th<br />
          <b>Likes: </b>Learning, Psionics, uncovering secrets<br />
          <b>Dislikes: </b>Politics, being forced to do things<br />
          <b>Strength: </b>Acute perception<br />
          <b>Weakness: </b>Being overly sympathetic<br />
          <b>Alignment:</b>Neutral Good<br />
          <b>Symbolic elements: </b>Navy/purple colors, stars
        </p>
      </div>



      <div class="box">
        <p>
          <b>Physical Strength: </b>5/10 <br />
          <b>Psionic Strength: </b>10/10 <br />
          <b>Intelligence: </b>7/10 <br />
          <b>Constitution: </b>7/10 <br />
          <b>Charisma: </b>4/10 <br />
          <b>Perception: </b>10/10 <br />
          <b>Courage: </b>9/10 <br />
          <b>Impulsiveness: </b>3/10 <br />
          <b>Seriousness: </b>5/10 <br />
          <b>Compassion: </b>9/10
        </p>
      </div>
    </div>

    <div class="blurb">
      <h2>Random Fact</h2>
      <p>
        Part of the reason why Kira has always been an extraordinary individual
        is because she was a Spirit Child. In extremely rare circ<a
        ></a>umstances when a child dies before they are born, their spirit may
        take over that of another child. The result of this event is often an
        extremely Psionically potent individual with a tendency to be involved
        in everything they come across. This is because to achieve this second
        chance of life, so to speak, the spirit must possess both immense power
        and extreme determination.
      </p>
      <p>
        The phenomenon of Spirit Children is one of the many secrets of Psionics
        that the royal family is responsible for safeguarding. Because she has
        access to these records, Kira may have her own suspicions of her
        origins, especially after a vision where she saw herself in the form of
        the ghost of a little girl. However, there is no solid way to prove her
        theories, and the only people who may have had some insight on the
        subject are long gone by the time she discovered this possibility.
      </p>

      <hr />
      <h2>Relations</h2>
      <ul class="list">
        <li>
          <h2>Nicola</h2>Kira
          highly respects and admires <b>Nicola</b> and can get extremely
          defensive if anyone provokes or disrespects her.
        </li>
        <li><h2>Mika</h2></li>
        <li><h2>Erik</h2></li>
        <li>
          <h2>Steffen</h2>
        </li>

        <li>
          <h2>Takanya</h2>
        </li>
        <li><h2>Leon</h2></li>
        <li><h2>Will</h2></li>
      </ul>
      <hr />

      <h2>Appearance</h2>
      <div class="ref">
        <h2><span>Human Form</span></h2>
        <img src="../../../assets/portraits/Kira.png">
        <p>
          <b>Build: </b>Slightly above average height, fairly skinny<br />
          <b>Hairstyle: </b>Long straight with bangs, sometimes worn in a bun<br />
          <b>Hair color: </b>Silver with a hint of light blue<br />
          <b>Eye color:</b> pale grayish blue<br />
          <b>Clothing: </b>Casual and easy to move around in. Has a preference
          for woolly or puffy clothes. Wears a cowl to hide her easily
          distinguishable hair when needed. <br />
          <b>Accessories: </b>Black square glasses for daywear.
          <br class="clr" />
        </p>
      </div>

      <div class="ref">
        <h2><span>Spirit Form</span></h2>
        <p>
          A silver cat/fox hybrid: cat face, fox ears and tail. Slightly larger
          than a large house cat.
        </p>
        <p>
          Silver fur covers her body. She has a thick furry collar that blends
          in with the face and body.
        </p>

        <p>
          Eyes are blue with black pupils. Whites of the eyes are hidden. Thick
          black markings surround the eyes.
        </p>
        <p>
          Wings optional, but usually present when she is intensely
          concentrating. They take the shape of raven wings, but aside from the
          primary feathers, most of the wings are covered with thick silver fur.
        </p>
        <br class="clr" />
      </div>

      <hr />
      <h2>Character Inspiration</h2>
      <p>
        One of my "out of nowhere" characters, Kira was a result of my extended
        XCOM story obsession. I had been writing character logs and stories for
        custom characters in various XCOM2 campaigns for years at that point,
        and had recently seen the conclusion of a very memorable campaign, which
        led to the development of the extended backstories of Mika and Nicola,
        among others. Kira was a brand new character that I wanted to add to
        that story, and my headcanon around her personality and abilities
        eventually led to the entire storyline of Skanerrak.
      </p>
    </div>

    <div class="gallery">

        <h2>Gallery</h2>

        <img src="http://i.imgur.com/dFHAlVx.jpeg">
        <img src="http://i.imgur.com/KHVYdPU.jpeg">
        <img src="http://i.imgur.com/3oEOHeD.png">
        <img src="http://i.imgur.com/IcSP0Rw.png">
        <img src="http://i.imgur.com/RxAB1Iu.jpg">

    </div>
</template>


<style>
.fluid-boxes {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

}
.box {
  border: solid 3px #2a2b33;
  border-radius: 2rem;
	width: 400px;
	padding: 30px;
  margin: 20px;
	color: #2a2b33;
	height: 240px;

}

.ref img {
	width: 250px;
	border: 5px solid #eee;
	margin-right: 20px;
	float: left;
}

.clr {
	clear: both;
}

.list {
	padding: 0;
	margin: 20px 0;
	clear: both;
}
.list li {
	padding: 10px;
	margin: 20px;
	width: 100%;
	background: #dfdfdf;
	list-style: none;
	min-height: 100px;
  height: auto;
  border-radius: 1rem;
}
.list li:hover {
	color: #333;
	background: #eee;
}

.list li h2 {
  font-size: 1rem;
  margin: 5px;
  text-align: left;
}

.gallery {
	text-align: center;
    background: #2a2b33;
	padding: 20px 0;
	height: auto;
	clear: both;
	color: #fff;
}
.gallery img {
	height: 250px;
	border: 5px solid #eee;
	margin: 20px;
}
.gallery img:hover, .ref img:hover {
	border: 5px solid #aaa;
}

.gallery h2 {
    color: #fff;
}
</style>