<template>
<nav>
    <ul>
        <li><router-link to="/Skanerrak">Skanerrak</router-link></li>
        <li><router-link to="/Skanerrak/History">History</router-link></li>
        <li><router-link to="/Skanerrak/Geography">Geography</router-link></li>
        <li><router-link to="/Skanerrak/Terminology">Terminology</router-link></li>       
        <li><router-link to="/Skanerrak/Characters">Characters</router-link></li>
        <li><a>Stories</a></li> |
        <li><router-link to="/">Home</router-link></li>
    </ul>
  </nav>
<div class="top"></div>
<div class="splash"></div>

  <div class="blurb">
  <p class="text-center">We are all subject to the Fates. But we must all act as if we are not, or die of despair.<br><b>The Golden Compass, Phillip Pullman</b></p>



<p>When nothing Existed, there was no Time.</p>

<p>For as long as there has been Existence, the Arrow of Time has never stopped.</p>


<p>From a lonely speck of dust afloat in space, to the mightiest of deities governing entire worlds, so long as there is Existence, there is no escape from the clutches of Time.</p>

<p>Time tirelessly takes all Existence towards Balance. </p>


<p>And thus the wheels turn.</p>

<p>And thus the old inspires the young, and the young inherits from the old, generation after generation, the cycle of hope and loss goes on and on.</p>

<p>This is how it has always been.</p>

<p>This is how it always will be.</p>

<p>Until the end of Time, the end of all Existence, when there will be no more Balance. </p>

<p>There will simply be no more.</p>

<hr><p>The difference between a living and non-living physical form is whether there is an associated Consciousness.</p>

<p>Consciousness is Life, and the physical matter that harbors it is its Body.</p>

<p>Consciousness, by nature, longs to be free, and defies the constraints of a physical body. For the body to truly harbor life, there must be a Spirit that chains the Consciousness to the Body.</p>

<p>The Body powers and shelters the <a href="#Spirit">Spirit</a>. The Spirit connects the Body with the Consciousness. The Consciousness teaches the Body to survive, to thrive, to reproduce, to evolve, to navigate the river of Time and become something more than it once was.</p>

<p>The Body, the Consciousness, and the Spirit that connects them: this is Life.</p>

<p>When a Life ends, the Consciousness, the defining feature of Life, disappears into nothingness. The Body remains, just as it always has been, forever a physical element of the universe it belongs to. And the Spirit is set free, free to answer the call of its brethren, reuniting forevermore with the Spirit of the Multiverse.</p>
<hr><p>The Multiverse is alive. </p>

<p>The Body of the Multiverse is the embodiment of all physical existence, and the rules that governs them. </p>

<p>The Consciousness of the Multiverse is the spark of randomness, the seed of chaos, the tiny sliver of unpredictability in a world that is otherwise entirely predetermined by its own rules. </p>

<p>The Spirit of the Multiverse binds the Consciousness to the Body, the origin and last refuge for all Spirits within the Multiverse.</p>

<p>At a point in Time, long long after the beginning of Existence, a tribe of humans worshipped the Body, Consciousness, and Spirit of the world they lived in.</p>

<p>They described the Body as an immovable, slumbering leviathan, chained to Existence itself, bringing law and order to the physical realm.</p>

<p>They described the Consciousness as a flickering Sprite, free and unpredictable, bringing change and chaos to the mundane.</p>

<p>They believed that the Spirit of the Universe is where their own Spirits came from, and where they will go when they perish, and the Spirit of all Spirits is what brought true Life to the Multiverse.</p>

<p>And thus they named the Spirit of the Multiverse after their own tribe, for their tribe was their entire world.</p>

<hr><p>Katasuna, the free-will of the Multiverse, found two starving children in an abandoned human house, and decided that this was the day for chaos.</p>

<p>Loteslea, the heartbeat of the Multiverse, saw a grieving young woman at her father's grave, and recognized that there must be balance in this world.</p>

<p>And so Katasuna looked to the baby girl, and fused her Spirit with the energies of the wild - with Light, as more commonly known - so that her Spirit may thrive without relying on the energy from her body. The girl - the elder of the twins, no more than a year old - now controlled the energies of the Multiverse.</p>

<p>And so Loteslea fused the young woman's Spirit with a deep, powerful anti-energy, the Darkness to the girl's newfound Light, so that Balance would be had.</p>

<p>And Katasuna then looked to the baby boy and sought to do the same. </p>

<p>But before the boy's Spirit was powered by Light, it had already let go of his body. The boy had, by all definitions, already been lost, and yet his newly powered Spirit somehow found its way back to his body, bringing it back to Life once more.</p>

<p>And Loteslea had missed this, for he had thought that the boy had already been lost.</p>

<p>And for that very brief moment, as the dying Spirit found its way back to its abandoned body, Entropy was reduced. Time was reversed.</p>

<p>Balance had been broken.</p>

</div>



</template>


<style>

.splash {
   background: url("https://i.imgur.com/O8ji3Bb.png") no-repeat center fixed;
   background-size: cover;
   height: 110vh;
   width: 100vw;
}


.page {
  width: 100vw;
  margin-top: 100vh;


}

</style>