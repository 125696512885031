<template>
<nav>
    <ul>
        <li><router-link to="/Skanerrak">Skanerrak</router-link></li>
        <li><router-link to="/Skanerrak/History">History</router-link></li>
        <li><router-link to="/Skanerrak/Geography">Geography</router-link></li>
        <li><router-link to="/Skanerrak/Terminology">Terminology</router-link></li>       
        <li><router-link to="/Skanerrak/Characters">Characters</router-link></li>
        <li><a>Stories</a></li> |
        <li><router-link to="/">Home</router-link></li>
    </ul>
  </nav>
  <div class="top"></div>

    <div class="blurb">
        <h2>Ander</h2>

<p class="text-center">


The last survivor of the fall<br>
Without a will to live at all<br>
The sun is setting as no one remains to hear my weakened call<br>
I've come a thousand miles alone<br>
Passed every kingdom's empty throne<br>
And I can't shake the feeling<br>
You'll arrange the stars to lead me home<br><b>Streets of Gold, Aviators</b></p>


    </div>

    <div class="fluid-boxes">
      <div class="box">
        <p><b>Birthplace: </b>Ibiak<br><b>Birthday: </b>August 29th<br><b>Likes: </b>Music, literature, history<br><b>Dislikes: </b>Psionics<br><b>Strength: </b>Discovering talent<br><b>Weakness: </b>Uncontrollable tempers<br><b>Alignment: </b>Neutral Evil<br><b>Symbolic elements: </b>dark red/black, sunset
        </p>
      </div>

  

      <div class="box">
        <p><b>Physical Strength: </b>9/10<br><b>Psionic Strength: </b>0/10<br><b>Intelligence: </b>6/10<br><b>Constitution: </b>4/10 <br><b>Charisma: </b>10/10<br><b>Perception: </b>7/10<br><b>Courage: </b>5/10 <br><b>Impulsiveness: </b>10/10 <br><b>Seriousness: </b>7/10 <br><b>Compassion: </b>5/10</p>
      </div>
    </div>

    <div class="blurb">
        <h2>Random Fact</h2>
<p>
Ander was very well versed in the field of literature and poetry and had always enjoyed excessively grandiose proses. He could also be a bit of a hopeless romantic at times. Unfortunately life's events prevented him from pursuing any of that. He was also interested in art, but distinctly lacked the talent for it.
</p>
<p>Despite despising Psionics his entire life, Ander was the first (and possibly only, depending on whether a certain scene involving Kira is considered canon or AU 0:-)) human whose Spirit was actively approached by <a href="#Will">the source of Psionics</a> after death and remained in the world, rather than immediately dissipating and returning to the World Spirit. 

</p><hr><h2>Relations</h2>
<ul class="list"><li><h2>Steffen</h2></li>
<li><h2>Lydia</h2></li>
<li><h2>The Psiborgs</h2></li>
<li><h2>Kira</h2></li>
<li><h2>Emmi</h2></li>
<li><h2>Lukas</h2></li>
</ul><hr><h2>Appearance</h2>
<div class="ref">
<h2><span>Human Form</span></h2>
<img src="../../../assets/portraits/Ander.png"><p>
<b>Build: </b>Average height and fairly stocky<br><b>Hairstyle: </b>Medium-long straight hair, often neatly parted with a bunch of hairspray<br><b>Hair color: </b>Brownish black<br><b>Eye color: </b>Midnight Blue<br><b>Clothing: </b>Almost always seen in a black suit<br><b>Accessories: </b>A large silver watch that he inherited from his father, but he likes to keep it hidden under his sleeve.
<br class="clr"></p></div>





<hr><h2>Character Inspiration</h2>
<p>
Early on, Ander's character came from the very simple need to have a "villain" character, inspired partially by a few real world terror events and partially by the Daud/Jessamine story in Dishonored. I took a liking to this character and could not help myself from making him a little less "evil" every time I rework the story, but I did have to stop myself from making him a truly "good" character, lol. His later developments were inspired by the Miracle of Sound song, Revolution Spark. His relationship with Steffen and dedication to his work are loosely moulded after Alex Yu from Prey and all the implied LORE (even the part about them being a bit of an ooc comic relief *cough*). There may also be a sprinkle of someone I know irl in his personality... but that came after most of his lore has already been established.
</p>
    </div>

    <div class="gallery">

        <h2>Gallery</h2>

        <img src="http://i.imgur.com/ueEaE6x.png"><img src="http://i.imgur.com/QzENClZ.png"><img src="http://i.imgur.com/JGIJGjn.png"><img src="http://i.imgur.com/yJePD1P.png">

    </div>
</template>


